<template>
  <v-main>
    <v-container class="fill-height" fluid>
      <v-row justify="center">
        <v-col cols="8">
          <h1>Terms Of Use</h1>
          <p>
            PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE USING THE SERVICES.
            BY ACCESSING THIS SITE OR USING ANY PART OF THE SITE OR ANY CONTENT
            OR SERVICES (AS EACH IS DEFINED BELOW) ON THE SITE, YOU AGREE TO
            BECOME BOUND BY THESE TERMS AND CONDITIONS. IF YOU DO NOT AGREE TO
            ALL THE TERMS AND CONDITIONS, THEN YOU MAY NOT ACCESS THE SITE OR
            USE THE CONTENT OR ANY SERVICES IN THE SITE. WIKIMEDIA DEUTSCHLAND'S
            ACCEPTANCE IS EXPRESSLY CONDITIONED UPON YOUR ASSENT TO ALL OF THESE
            TERMS AND CONDITIONS, TO THE EXCLUSION OF ALL OTHER TERMS; IF THESE
            TERMS AND CONDITIONS ARE CONSIDERED AN OFFER BY WIKIMEDIA
            DEUTSCHLAND. ACCEPTANCE IS EXPRESSLY LIMITED TO THESE TERMS.
          </p>
          <h2>Modifications of Terms of Use</h2>
          <p>
            Wikimedia Deutschland reserves the right, at its sole discretion, to
            modify or replace the Terms of Use at any time. If the alterations
            constitute a material change to the Terms of Use, Wikimedia
            Deutschland will notify you in your event feed and by email if
            allowed. What constitutes a "material change" will be determined at
            Wikimedia Deutschland’s sole discretion, in good faith and using
            common sense and reasonable judgement. You shall be responsible for
            reviewing and becoming familiar with any such modifications. Use of
            the Services by you following such notification constitutes your
            acceptance of the terms and conditions of the Terms of Use as
            modified.
          </p>
          <h2>Services</h2>
          <p>
            Wikimedia Deutschland hosts various MediaWiki and Wikibase based
            projects and related services. Wikimedia Deutschland's public
            content is available to anyone on the Internet.
          </p>
          <h2>Content</h2>
          <p>
            Wikimedia Deutschland is a hosting provider, and takes no
            responsibility for the content uploaded by third parties. Wikimedia
            Deutschland sites are provided "as is". Wikimedia Deutschland has no
            responsibility for lack of availability of any site or of any
            particular content. You should be aware that the sites can be edited
            by the public, and, as such, may contain inappropriate content at
            any time. Any use you make of content hosted by Wikimedia
            Deutschland must be in line with the terms of that site's license.
          </p>
          <p>
            Any content you upload or post to any Wikimedia Deutschland site
            must be compatible with the relevant license, not violate another
            person's intellectual property or infringe on any copyright, patent,
            trademark, trade secret, or other proprietary rights. No content
            shall be added which violates any applicable law, including local,
            state, national, and international laws. You understand that
            Wikimedia Deutschland does not control, and is not responsible for
            Content made available through the Service, and that by using the
            Service, you may be exposed to Content that is offensive, indecent,
            inaccurate, misleading, or otherwise objectionable. Furthermore,
            under no circumstances will Wikimedia Deutschland be liable in any
            way for any Content or for any loss or damage of any kind incurred
            as a result of the use of any Content posted, emailed or otherwise
            made available via the Service.
          </p>
          <p>
            You agree that you will not make any changes to Wikimedia
            Deutschland that are in breach of the Content Policy and will make
            efforts to remove or report any content which may be in breach of
            such conditions as described in the policy.
          </p>
          <p>
            You acknowledge that Wikimedia Deutschland does not pre-screen or
            approve Content, but that Wikimedia Deutschland shall have the right
            (but not the obligation) in its sole discretion to refuse, delete or
            move any Content that is available via the Service, for violating
            the Terms of use or for any other reason.
          </p>
          <h3>Content Policy</h3>
          <p>
            To allow users to browse Wikimedia Deutschland sites freely without
            distress or upset, Wikimedia Deutschland users should not publish or
            link to any of the following types of prohibited content. Wikimedia
            Deutschland users should take action to remove such content or
            report violations.
          </p>
          <h4>Prohibited Content</h4>
          <ul>
            <li>Pornography, adult or mature content</li>
            <li>Violent content</li>
            <li>
              Hate speech (including content that incites hatred or promotes
              violence against individuals or groups based on race or ethnic
              origin, religion, disability, gender, age, veteran status or
              sexual orientation/gender identity), harassment, bullying or
              similar content that advocates harm against an individual or group
            </li>
            <li>Excessive profanity</li>
            <li>Hacking/cracking content</li>
            <li>Illicit drugs and drug paraphernalia content</li>
            <li>
              Content that promotes, sells or advertises products obtained from
              endangered or threatened species
            </li>
            <li>Sales of beer or spirits</li>
            <li>Sales of tobacco or tobacco-related products</li>
            <li>Sales of prescription drugs</li>
            <li>
              Sales of weapons or ammunition (e.g. firearms, firearm components,
              fighting knives, stun guns)
            </li>
            <li>Sales or distribution of coursework or student essays</li>
            <li>
              Content regarding programmes which compensate users for clicking
              ads or offers, performing searches, surfing websites or reading
              emails
            </li>
            <li>
              Any other content that is illegal, promotes illegal activity or
              infringes on the legal rights of others
            </li>
          </ul>
          <br>
          <h2>Indemnity</h2>
          <p>
            You will indemnify and hold harmless Wikimedia Deutschland, its
            parents, subsidiaries, affiliates, customers, vendors, officers and
            employees from any liability, damage or cost (including reasonable
            attorneys. fees and cost) from (i) any claim or demand made by any
            third party due to or arising out of your access to the Site, use of
            the Services, violation of the Terms of Use by you, or the
            infringement by you, or any third party using your account or User
            ID, of any intellectual property or other right of any person or
            entity.
          </p>
          <h2>Data Protection</h2>
          <p>
            Wikimedia Deutschland processes your personal data according to its
            <a href="/privacy-policy">privacy policy</a>.
          </p>
          <p>
            Regarding the Content you upload to the Service, Wikimedia
            Deutschland acts as your data processor. For this purpose you and
            Wikimedia Deutschland conclude the Data Processing Agreement in the
            Appendix.
          </p>
          <h2>Warranty Disclaimers</h2>
          <p>
            You acknowledge that Wikimedia Deutschland has no control over, and
            no duty to take any action regarding: which users gain access to the
            Site or use the Services; what effects the Content may have on you;
            how you may interpret or use the Content; or what actions you may
            take as a result of having been exposed to the Content. You release
            Wikimedia Deutschland from all liability for you having acquired or
            not acquired Content through the Site or the Services.
          </p>
          <p>
            The Site or Services may contain, or direct you to sites containing,
            information that some people may find offensive or inappropriate.
            Wikimedia Deutschland makes no representations concerning any
            content contained in or accessed through the Site or Services, and
            Wikimedia Deutschland will not be responsible or liable for the
            accuracy, copyright compliance, legality or decency of material
            contained in or accessed through the Site or the Services.
          </p>
          <p>
            THE SERVICE, CONTENT, AND SITE ARE PROVIDED ON AN "AS IS" BASIS,
            WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED,
            INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR
            NON-INFRINGEMENT. SOME STATES DO NOT ALLOW LIMITATIONS ON HOW LONG
            AN IMPLIED WARRANTY LASTS, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO
            YOU.
          </p>
          <h2>Changes to sites</h2>
          <p>
            Wikimedia Deutschland reserves the right to close, move, merge, or
            rename a site for any reason. Wikimedia Deutschland shall not be
            held liable for any modification, suspension or discontinuance of
            any site, or of the service as a whole.
          </p>
          <h2>Links</h2>
          <p>
            The Services may provide, or third parties may provide, links to
            other World Wide Web sites or resources. Because Wikimedia
            Deutschland has no control over such sites and resources, you
            acknowledge and agree that Wikimedia Deutschland is not responsible
            for the availability of such external sites or resources, and does
            not endorse and is not responsible or liable for any Content,
            advertising, products or other materials on or available from such
            sites or resources. You further acknowledge and agree that Wikimedia
            Deutschland shall not be responsible or liable, directly or
            indirectly, for any damage or loss caused or alleged to be caused by
            or in connection with use of or reliance on any such Content, goods
            or services available on or through any such site or resource.
          </p>
          <h2>Copyright Complaints</h2>
          <p>
            We respect the intellectual property rights of others and we
            prohibit users from uploading, posting or otherwise transmitting on
            the Wikimedia Deutschland website or service any materials that
            violate another party's intellectual property rights. When we
            receive proper Notification of Alleged Copyright Infringement, we
            promptly remove or disable access to the allegedly infringing
            material and terminate the accounts of repeat infringers as
            described herein in accordance with the Digital Millennium Copyright
            Act. If you believe that any material on the Site infringes upon any
            copyright which you own or control, you may send a written
            notification of such infringement to us.
          </p>
          <h2>User Disputes</h2>
          <p>
            You are solely responsible for your interactions with other
            Wikimedia Deutschland users. We reserve the right, but have no
            obligation, to monitor disputes between you and other users.
          </p>
          <h2>Submissions</h2>
          <p>
            You acknowledge and agree that any questions, comments, suggestions,
            ideas, feedback or other information about the Site or the Service
            ("Submissions"), provided by you to Wikimedia Deutschland on the
            topic of the the Wikibase.cloud service are non-confidential and
            shall become the sole property of Wikimedia Deutschland. Wikimedia
            Deutschland shall own exclusive rights, including all intellectual
            property rights, and shall be entitled to the unrestricted use and
            dissemination of these Submissions for any purpose, commercial or
            otherwise, without acknowledgment or compensation to you.
          </p>
          <h2>Termination</h2>
          <p>
            Wikimedia Deutschland may terminate or suspend any and all Services
            and your user account immediately, without prior notice or
            liability, if you breach any of the terms or conditions of the Terms
            of Use Upon termination of your account, your right to use the
            Services will immediately cease. If you wish to terminate your
            account, you may simply discontinue using the Services. All
            provisions of the Terms of Use which by their nature should survive
            termination shall survive termination, including, without
            limitation, ownership provisions, warranty disclaimers, indemnity
            and limitations of liability.
          </p>
          <h2>Other</h2>
          <p>
            These Terms of Use constitute the entire agreement between you and
            Wikimedia Deutschland regarding the use of the Site and/or the
            Service, superseding any prior agreements between you and Wikimedia
            Deutschland relating to your use of the Site or the Service. The
            failure of Wikimedia Deutschland to exercise or enforce any right or
            provision of these Terms of Use shall not constitute a waiver of
            such right or provision in that or any other instance.
          </p>
          <p>
            If any provision of this Agreement is held invalid, the remainder of
            this Agreement shall continue in full force and effect. If any
            provision of these Terms of Use shall be deemed unlawful, void or
            for any reason unenforceable, then that provision shall be deemed
            severable from these Terms of Use and shall not affect the validity
            and enforceability of any remaining provisions.
          </p>
          <p></p>
          <p></p>
          <p></p>
          <h1>Appendix: Data Processing Agreement</h1>
          <h4>
            Standard contractual clauses (Commission Implementing Decision (EU)
            2021/915 of 4 June 202)
          </h4>
          <h5>SECTION I</h5>
          <h5>Clause 1</h5>
          <h5 class="italic">Purpose and scope</h5>
          <table>
            <tbody>
              <tr>
                <td class="first-column">
                  <p>(a)</p>
                </td>
                <td colspan="1" rowspan="1">
                  <p>
                    The purpose of these Standard Contractual Clauses (the
                    Clauses) is to ensure compliance with Article 28(3) and (4)
                    of Regulation (EU) 2016/679 of the European Parliament and
                    of the Council of 27 April 2016 on the protection of natural
                    persons with regard to the processing of personal data and
                    on the free movement of such data, and repealing Directive
                    95/46/EC (General Data Protection Regulation).
                  </p>
                </td>
              </tr>
              <tr>
                <td class="first-column">
                  <p>(b)</p>
                </td>
                <td colspan="1" rowspan="1">
                  <p>
                    The controllers and processors listed in Annex I have agreed
                    to these Clauses in order to ensure compliance with Article
                    28(3) and (4) of Regulation (EU) 2016/679 and/or Article
                    29(3) and (4) of Regulation (EU) 2018/1725.
                  </p>
                </td>
              </tr>
              <tr>
                <td class="first-column">
                  <p>(c)</p>
                </td>
                <td colspan="1" rowspan="1">
                  <p>
                    These Clauses apply to the processing of personal data as
                    specified in Annex II.
                  </p>
                </td>
              </tr>
              <tr>
                <td class="first-column">
                  <p>(d)</p>
                </td>
                <td colspan="1" rowspan="1">
                  <p>Annexes I to IV are an integral part of the Clauses.</p>
                </td>
              </tr>
              <tr>
                <td class="first-column">
                  <p>(e)</p>
                </td>
                <td colspan="1" rowspan="1">
                  <p>
                    These Clauses are without prejudice to obligations to which
                    the controller is subject by virtue of Regulation (EU)
                    2016/679 and/or Regulation (EU) 2018/1725.
                  </p>
                </td>
              </tr>
              <tr>
                <td class="first-column">
                  <p>(f)</p>
                </td>
                <td colspan="1" rowspan="1">
                  <p>
                    These Clauses do not by themselves ensure compliance with
                    obligations related to international transfers in accordance
                    with Chapter V of Regulation (EU) 2016/679 and/or Regulation
                    (EU) 2018/1725.
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <h5>Clause 2</h5>
          <h5 class="italic">Invariability of the Clauses</h5>
          <table>
            <tbody>
              <tr>
                <td class="first-column">
                  <p>(a)</p>
                </td>
                <td colspan="1" rowspan="1">
                  <p>
                    The Parties undertake not to modify the Clauses, except for
                    adding information to the Annexes or updating information in
                    them.
                  </p>
                </td>
              </tr>
              <tr>
                <td class="first-column">
                  <p>(b)</p>
                </td>
                <td colspan="1" rowspan="1">
                  <p>
                    This does not prevent the Parties from including the
                    standard contractual clauses laid down in these Clauses in a
                    broader contract, or from adding other clauses or additional
                    safeguards provided that they do not directly or indirectly
                    contradict the Clauses or detract from the fundamental
                    rights or freedoms of data subjects.
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <h5>Clause 3</h5>
          <h5 class="italic">Interpretation</h5>
          <table>
            <tbody>
              <tr>
                <td class="first-column">
                  <p>(a)</p>
                </td>
                <td colspan="1" rowspan="1">
                  <p>
                    Where these Clauses use the terms defined in Regulation (EU)
                    2016/679 or Regulation (EU) 2018/1725 respectively, those
                    terms shall have the same meaning as in that Regulation.
                  </p>
                </td>
              </tr>
              <tr>
                <td class="first-column">
                  <p>(b)</p>
                </td>
                <td colspan="1" rowspan="1">
                  <p>
                    These Clauses shall be read and interpreted in the light of
                    the provisions of Regulation (EU) 2016/679 or Regulation
                    (EU) 2018/1725 respectively.
                  </p>
                </td>
              </tr>
              <tr>
                <td class="first-column">
                  <p>(c)</p>
                </td>
                <td colspan="1" rowspan="1">
                  <p>
                    These Clauses shall not be interpreted in a way that runs
                    counter to the rights and obligations provided for in
                    Regulation (EU) 2016/679 / Regulation (EU) 2018/1725 or in a
                    way that prejudices the fundamental rights or freedoms of
                    the data subjects.
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <h5>Clause 4</h5>
          <h5 class="italic">Hierarchy</h5>
          <p>
            In the event of a contradiction between these Clauses and the
            provisions of related agreements between the Parties existing at the
            time when these Clauses are agreed or entered into thereafter, these
            Clauses shall prevail.
          </p>
          <h5>SECTION II</h5>
          <h5>OBLIGATIONS OF THE PARTIES</h5>
          <h5>Clause 5</h5>
          <h5 class="italic">Description of processing(s)</h5>
          <p>
            The details of the processing operations, in particular the
            categories of personal data and the purposes of processing for which
            the personal data is processed on behalf of the controller, are
            specified in Annex II.
          </p>
          <h5>Clause 6</h5>
          <h5 class="italic">Obligations of the Parties</h5>
          <h5>6.1. Instructions</h5>
          <br>
          <table>
            <tbody>
              <tr>
                <td class="first-column">
                  <p>(a)</p>
                </td>
                <td colspan="1" rowspan="1">
                  <p>
                    The processor shall process personal data only on documented
                    instructions from the controller, unless required to do so
                    by Union or Member State law to which the processor is
                    subject. In this case, the processor shall inform the
                    controller of that legal requirement before processing,
                    unless the law prohibits this on important grounds of public
                    interest. Subsequent instructions may also be given by the
                    controller throughout the duration of the processing of
                    personal data. These instructions shall always be
                    documented.
                  </p>
                </td>
              </tr>
              <tr>
                <td class="first-column">
                  <p>(b)</p>
                </td>
                <td colspan="1" rowspan="1">
                  <p>
                    The processor shall immediately inform the controller if, in
                    the processor’s opinion, instructions given by the
                    controller infringe Regulation (EU) 2016/679 / Regulation
                    (EU) 2018/1725 or the applicable Union or Member State data
                    protection provisions.
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <h5>6.2. Purpose limitation</h5>
          <p>
            The processor shall process the personal data only for the specific
            purpose(s) of the processing, as set out in Annex II, unless it
            receives further instructions from the controller.
          </p>
          <h5>6.3. Duration of the processing of personal data</h5>
          <p>
            Processing by the processor shall only take place for the duration
            specified in Annex II.
          </p>
          <h5>6.4. Security of processing</h5>
          <p></p>
          <table>
            <tbody>
              <tr>
                <td class="first-column">
                  <p>(a)</p>
                </td>
                <td colspan="1" rowspan="1">
                  <p>
                    The processor shall at least implement the technical and
                    organisational measures specified in Annex III to ensure the
                    security of the personal data. This includes protecting the
                    data against a breach of security leading to accidental or
                    unlawful destruction, loss, alteration, unauthorised
                    disclosure or access to the data (personal data breach). In
                    assessing the appropriate level of security, the Parties
                    shall take due account of the state of the art, the costs of
                    implementation, the nature, scope, context and purposes of
                    processing and the risks involved for the data subjects.
                  </p>
                </td>
              </tr>
              <tr>
                <td class="first-column">
                  <p>(b)</p>
                </td>
                <td colspan="1" rowspan="1">
                  <p>
                    The processor shall grant access to the personal data
                    undergoing processing to members of its personnel only to
                    the extent strictly necessary for implementing, managing and
                    monitoring of the contract. The processor shall ensure that
                    persons authorised to process the personal data received
                    have committed themselves to confidentiality or are under an
                    appropriate statutory obligation of confidentiality.
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <h5>6.5. Sensitive data</h5>
          <p>
            If the processing involves personal data revealing racial or ethnic
            origin, political opinions, religious or philosophical beliefs, or
            trade union membership, genetic data or biometric data for the
            purpose of uniquely identifying a natural person, data concerning
            health or a person’s sex life or sexual orientation, or data
            relating to criminal convictions and offences (“sensitive data”),
            the processor shall apply specific restrictions and/or additional
            safeguards.
          </p>
          <h5>6.6. Documentation and compliance</h5>
          <p></p>
          <table>
            <tbody>
              <tr>
                <td class="first-column">
                  <p>(a)</p>
                </td>
                <td colspan="1" rowspan="1">
                  <p>
                    The Parties shall be able to demonstrate compliance with
                    these Clauses.
                  </p>
                </td>
              </tr>
              <tr>
                <td class="first-column">
                  <p>(b)</p>
                </td>
                <td colspan="1" rowspan="1">
                  <p>
                    The processor shall deal promptly and adequately with
                    inquiries from the controller about the processing of data
                    in accordance with these Clauses.
                  </p>
                </td>
              </tr>
              <tr>
                <td class="first-column">
                  <p>(c)</p>
                </td>
                <td colspan="1" rowspan="1">
                  <p>
                    The processor shall make available to the controller all
                    information necessary to demonstrate compliance with the
                    obligations that are set out in these Clauses and stem
                    directly from Regulation (EU) 2016/679 and/or Regulation
                    (EU) 2018/1725. At the controller’s request, the processor
                    shall also permit and contribute to audits of the processing
                    activities covered by these Clauses, at reasonable intervals
                    or if there are indications of non-compliance. In deciding
                    on a review or an audit, the controller may take into
                    account relevant certifications held by the processor.
                  </p>
                </td>
              </tr>
              <tr>
                <td class="first-column">
                  <p>(d)</p>
                </td>
                <td colspan="1" rowspan="1">
                  <p>
                    The controller may choose to conduct the audit by itself or
                    mandate an independent auditor. Audits may also include
                    inspections at the premises or physical facilities of the
                    processor and shall, where appropriate, be carried out with
                    reasonable notice.
                  </p>
                </td>
              </tr>
              <tr>
                <td class="first-column">
                  <p>(e)</p>
                </td>
                <td colspan="1" rowspan="1">
                  <p>
                    The Parties shall make the information referred to in this
                    Clause, including the results of any audits, available to
                    the competent supervisory authority/ies on request.
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <h5>6.7. Use of sub-processors</h5>
          <p></p>
          <table>
            <tbody>
              <tr>
                <td class="first-column">
                  <p>(a)</p>
                </td>
                <td colspan="1" rowspan="1">
                  <p>
                    GENERAL WRITTEN AUTHORISATION: The processor has the
                    controller’s general authorisation for the engagement of
                    sub-processors from an agreed list. The processor shall
                    specifically inform in writing the controller of any
                    intended changes of that list through the addition or
                    replacement of sub-processors at least two months
                    in advance, thereby giving the controller sufficient time to
                    be able to object to such changes prior to the engagement of
                    the concerned sub-processor(s). The processor shall provide
                    the controller with the information necessary to enable the
                    controller to exercise the right to object.
                  </p>
                </td>
              </tr>
              <tr>
                <td class="first-column">
                  <p>(b)</p>
                </td>
                <td colspan="1" rowspan="1">
                  <p>
                    Where the processor engages a sub-processor for carrying out
                    specific processing activities (on behalf of the
                    controller), it shall do so by way of a contract which
                    imposes on the sub-processor, in substance, the same data
                    protection obligations as the ones imposed on the data
                    processor in accordance with these Clauses. The processor
                    shall ensure that the sub-processor complies with the
                    obligations to which the processor is subject pursuant to
                    these Clauses and to Regulation (EU) 2016/679 and/or
                    Regulation (EU) 2018/1725.
                  </p>
                </td>
              </tr>
              <tr>
                <td class="first-column">
                  <p>(c)</p>
                </td>
                <td colspan="1" rowspan="1">
                  <p>
                    At the controller’s request, the processor shall provide a
                    copy of such a sub-processor agreement and any subsequent
                    amendments to the controller. To the extent necessary to
                    protect business secret or other confidential information,
                    including personal data, the processor may redact the text
                    of the agreement prior to sharing the copy.
                  </p>
                </td>
              </tr>
              <tr>
                <td class="first-column">
                  <p>(d)</p>
                </td>
                <td colspan="1" rowspan="1">
                  <p>
                    The processor shall remain fully responsible to the
                    controller for the performance of the sub-processor’s
                    obligations in accordance with its contract with the
                    processor. The processor shall notify the controller of any
                    failure by the sub-processor to fulfil its contractual
                    obligations.
                  </p>
                </td>
              </tr>
              <tr>
                <td class="first-column">
                  <p>(e)</p>
                </td>
                <td colspan="1" rowspan="1">
                  <p>
                    The processor shall agree a third party beneficiary clause
                    with the sub-processor whereby - in the event the processor
                    has factually disappeared, ceased to exist in law or has
                    become insolvent - the controller shall have the right to
                    terminate the sub-processor contract and to instruct the
                    sub-processor to erase or return the personal data.
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <h5>6.8. International transfers</h5>
          <table>
            <tbody>
              <tr>
                <td class="first-column">
                  <p>(a)</p>
                </td>
                <td colspan="1" rowspan="1">
                  <p>
                    Any transfer of data to a third country or an international
                    organisation by the processor shall be done only on the
                    basis of documented instructions from the controller or in
                    order to fulfil a specific requirement under Union or Member
                    State law to which the processor is subject and shall take
                    place in compliance with Chapter V of Regulation (EU)
                    2016/679 or Regulation (EU) 2018/1725.
                  </p>
                </td>
              </tr>
              <tr>
                <td class="first-column">
                  <p>(b)</p>
                </td>
                <td colspan="1" rowspan="1">
                  <p>
                    The controller agrees that where the processor engages a
                    sub-processor in accordance with Clause 7.7. for carrying
                    out specific processing activities (on behalf of the
                    controller) and those processing activities involve a
                    transfer of personal data within the meaning of Chapter V of
                    Regulation (EU) 2016/679, the processor and the
                    sub-processor can ensure compliance with Chapter V of
                    Regulation (EU) 2016/679 by using standard contractual
                    clauses adopted by the Commission in accordance with of
                    Article 46(2) of Regulation (EU) 2016/679, provided the
                    conditions for the use of those standard contractual clauses
                    are met.
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <h5>Clause 7</h5>
          <h5 class="italic">Assistance to the controller</h5>
          <table>
            <tbody>
              <tr>
                <td class="first-column">
                  <p>(a)</p>
                </td>
                <td colspan="1" rowspan="1">
                  <p>
                    The processor shall promptly notify the controller of any
                    request it has received from the data subject. It shall not
                    respond to the request itself, unless authorised to do so by
                    the controller.
                  </p>
                </td>
              </tr>
              <tr>
                <td class="first-column">
                  <p>(b)</p>
                </td>
                <td colspan="1" rowspan="1">
                  <p>
                    The processor shall assist the controller in fulfilling its
                    obligations to respond to data subjects’ requests to
                    exercise their rights, taking into account the nature of the
                    processing. In fulfilling its obligations in accordance with
                    (a) and (b), the processor shall comply with the
                    controller’s instructions
                  </p>
                </td>
              </tr>
              <tr>
                <td class="first-column">
                  <p>(c)</p>
                </td>
                <td colspan="1" rowspan="1">
                  <p>
                    In addition to the processor’s obligation to assist the
                    controller pursuant to Clause 8(b), the processor shall
                    furthermore assist the controller in ensuring compliance
                    with the following obligations, taking into account the
                    nature of the data processing and the information available
                    to the processor:
                  </p>
                  <table>
                    <tbody>
                      <tr>
                        <td class="first-column">
                          <p>(1)</p>
                        </td>
                        <td colspan="1" rowspan="1">
                          <p>
                            the obligation to carry out an assessment of the
                            impact of the envisaged processing operations on the
                            protection of personal data (a ‘data protection
                            impact assessment’) where a type of processing is
                            likely to result in a high risk to the rights and
                            freedoms of natural persons;
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td class="first-column">
                          <p>(2)</p>
                        </td>
                        <td colspan="1" rowspan="1">
                          <p>
                            the obligation to consult the competent supervisory
                            authority/ies prior to processing where a data
                            protection impact assessment indicates that the
                            processing would result in a high risk in the
                            absence of measures taken by the controller to
                            mitigate the risk;
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td class="first-column">
                          <p>(3)</p>
                        </td>
                        <td colspan="1" rowspan="1">
                          <p>
                            the obligation to ensure that personal data is
                            accurate and up to date, by informing the controller
                            without delay if the processor becomes aware that
                            the personal data it is processing is inaccurate or
                            has become outdated;
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td class="first-column">
                          <p>(4)</p>
                        </td>
                        <td colspan="1" rowspan="1">
                          <p>
                            the obligations in Article 32 of Regulation (EU)
                            2016/679.
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <p></p>
                </td>
              </tr>
              <tr>
                <td class="first-column">
                  <p>(d)</p>
                </td>
                <td colspan="1" rowspan="1">
                  <p>
                    The Parties shall set out in Annex III the appropriate
                    technical and organisational measures by which the processor
                    is required to assist the controller in the application of
                    this Clause as well as the scope and the extent of the
                    assistance required.
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <h5>Clause 8</h5>
          <h5 class="italic">Notification of personal data breach</h5>
          <p>
            In the event of a personal data breach, the processor shall
            cooperate with and assist the controller for the controller to
            comply with its obligations under Articles 33 and 34 of Regulation
            (EU) 2016/679 or under Articles 34 and 35 of Regulation (EU)
            2018/1725, where applicable, taking into account the nature of
            processing and the information available to the processor.
          </p>
          <p>8.1 Data breach concerning data processed by the controller</p>
          <p>
            In the event of a personal data breach concerning data processed by
            the controller, the processor shall assist the controller:
          </p>
          <p></p>
          <table>
            <tbody>
              <tr>
                <td class="first-column">
                  <p>(a)</p>
                </td>
                <td colspan="1" rowspan="1">
                  <p>
                    in notifying the personal data breach to the competent
                    supervisory authority/ies, without undue delay after the
                    controller has become aware of it, where relevant/(unless
                    the personal data breach is unlikely to result in a risk to
                    the rights and freedoms of natural persons);
                  </p>
                </td>
              </tr>
              <tr>
                <td class="first-column">
                  <p>(b)</p>
                </td>
                <td colspan="1" rowspan="1">
                  <p>
                    in obtaining the following information which, pursuant to
                    Article 33(3) of Regulation (EU) 2016/679, shall be stated
                    in the controller’s notification, and must at least include:
                  </p>
                  <table>
                    <tbody>
                      <tr>
                        <td class="first-column">
                          <p>(1)</p>
                        </td>
                        <td colspan="1" rowspan="1">
                          <p>
                            the nature of the personal data including where
                            possible, the categories and approximate number of
                            data subjects concerned and the categories and
                            approximate number of personal data records
                            concerned;
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td class="first-column">
                          <p>(2)</p>
                        </td>
                        <td colspan="1" rowspan="1">
                          <p>
                            the likely consequences of the personal data breach;
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td class="first-column">
                          <p>(3)</p>
                        </td>
                        <td colspan="1" rowspan="1">
                          <p>
                            the measures taken or proposed to be taken by the
                            controller to address the personal data breach,
                            including, where appropriate, measures to mitigate
                            its possible adverse effects.
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <p></p>
                </td>
              </tr>
            </tbody>
          </table>
          <p>
            Where, and insofar as, it is not possible to provide all this
            information at the same time, the initial notification shall contain
            the information then available and further information shall, as it
            becomes available, subsequently be provided without undue delay.
          </p>
          <p></p>
          <table>
            <tbody>
              <tr>
                <td class="first-column">
                  <p>(c)</p>
                </td>
                <td colspan="1" rowspan="1">
                  <p>
                    in complying, pursuant to Article 34 of Regulation (EU)
                    2016/679, with the obligation to communicate without undue
                    delay the personal data breach to the data subject, when the
                    personal data breach is likely to result in a high risk to
                    the rights and freedoms of natural persons.
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <h5>8.2 Data breach concerning data processed by the processor</h5>
          <p>
            In the event of a personal data breach concerning data processed by
            the processor, the processor shall notify the controller without
            undue delay after the processor having become aware of the breach.
            Such notification shall contain, at least:
          </p>
          <p></p>
          <table>
            <tbody>
              <tr>
                <td class="first-column">
                  <p>(a)</p>
                </td>
                <td colspan="1" rowspan="1">
                  <p>
                    a description of the nature of the breach (including, where
                    possible, the categories and approximate number of data
                    subjects and data records concerned);
                  </p>
                </td>
              </tr>
              <tr>
                <td class="first-column">
                  <p>(b)</p>
                </td>
                <td colspan="1" rowspan="1">
                  <p>
                    the details of a contact point where more information
                    concerning the personal data breach can be obtained;
                  </p>
                </td>
              </tr>
              <tr>
                <td class="first-column">
                  <p>(c)</p>
                </td>
                <td colspan="1" rowspan="1">
                  <p>
                    its likely consequences and the measures taken or proposed
                    to be taken to address the breach, including to mitigate its
                    possible adverse effects.
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <p>
            Where, and insofar as, it is not possible to provide all this
            information at the same time, the initial notification shall contain
            the information then available and further information shall, as it
            becomes available, subsequently be provided without undue delay.
          </p>
          <p>
            The Parties shall set out in Annex III all other elements to be
            provided by the processor when assisting the controller in the
            compliance with the controller’s obligations under Articles 33 and
            34 of Regulation (EU) 2016/679.
          </p>
          <br>
          <h5>SECTION III</h5>
          <h5>FINAL PROVISIONS</h5>
          <h5>Clause 9</h5>
          <h5 class="italic">Non-compliance with the Clauses and termination</h5>
          <table>
            <tbody>
              <tr>
                <td class="first-column">
                  <p>(a)</p>
                </td>
                <td colspan="1" rowspan="1">
                  <p>
                    Without prejudice to any provisions of Regulation (EU)
                    2016/679 and/or Regulation (EU) 2018/1725, in the event that
                    the processor is in breach of its obligations under these
                    Clauses, the controller may instruct the processor to
                    suspend the processing of personal data until the latter
                    complies with these Clauses or the contract is terminated.
                    The processor shall promptly inform the controller in case
                    it is unable to comply with these Clauses, for whatever
                    reason.
                  </p>
                </td>
              </tr>
              <tr>
                <td class="first-column">
                  <p>(b)</p>
                </td>
                <td colspan="1" rowspan="1">
                  <p>
                    The controller shall be entitled to terminate the contract
                    insofar as it concerns processing of personal data in
                    accordance with these Clauses if:
                  </p>
                  <table>
                    <tbody>
                      <tr>
                        <td class="first-column">
                          <p>(1)</p>
                        </td>
                        <td colspan="1" rowspan="1">
                          <p>
                            the processing of personal data by the processor has
                            been suspended by the controller pursuant to point
                            (a) and if compliance with these Clauses is not
                            restored within a reasonable time and in any event
                            within one month following suspension;
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td class="first-column">
                          <p>(2)</p>
                        </td>
                        <td colspan="1" rowspan="1">
                          <p>
                            the processor is in substantial or persistent breach
                            of these Clauses or its obligations under Regulation
                            (EU) 2016/679 and/or Regulation (EU) 2018/1725;
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td class="first-column">
                          <p>(3)</p>
                        </td>
                        <td colspan="1" rowspan="1">
                          <p>
                            the processor fails to comply with a binding
                            decision of a competent court or the competent
                            supervisory authority/ies regarding its obligations
                            pursuant to these Clauses or to Regulation (EU)
                            2016/679 and/or Regulation (EU) 2018/1725.
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <p></p>
                </td>
              </tr>
              <tr>
                <td class="first-column">
                  <p>(c)</p>
                </td>
                <td colspan="1" rowspan="1">
                  <p>
                    The processor shall be entitled to terminate the contract
                    insofar as it concerns processing of personal data under
                    these Clauses where, after having informed the controller
                    that its instructions infringe applicable legal requirements
                    in accordance with Clause 7.1 (b), the controller insists on
                    compliance with the instructions.
                  </p>
                </td>
              </tr>
              <tr>
                <td class="first-column">
                  <p>(d)</p>
                </td>
                <td colspan="1" rowspan="1">
                  <p>
                    Following termination of the contract, the processor shall,
                    at the choice of the controller, delete all personal data
                    processed on behalf of the controller and certify to the
                    controller that it has done so, or, return all the personal
                    data to the controller and delete existing copies unless
                    Union or Member State law requires storage of the personal
                    data. Until the data is deleted or returned, the processor
                    shall continue to ensure compliance with these Clauses.
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <hr />
          <br>
          <h5>ANNEX I</h5>
          <h5>List of parties</h5>
          <p>
            <b>Controller(s):</b> [<i>Identity and contact details of the controller(s),
            and, where applicable, of the controller’s data protection officer</i>]
          </p>
          <p>The User who concluded the Agreement above.</p>
          <p>
            <b>Processor(s):</b> [<i>Identity and contact details of the processor(s) and,
            where applicable, of the processor’s data protection officer</i>]
          </p>
          <p>
            Wikimedia Deutschland e. V., Tempelhofer Ufer 23-24, 10369 Berlin
          </p>
          <br>
          <h5>ANNEX II</h5>
          <h5>Description of the processing</h5>
          <p class="italic">Categories of data subjects whose personal data is processed</p>
          <p class="processing">
            - Any categories of data subjects contained in the data uploaded by
            the User.
          </p>
          <p class="italic">Categories of personal data processed</p>
          <p class="processing">
            - Any categories of personal data contained in the data uploaded by
            the User.
          </p>
          <p class="italic">
            Sensitive data processed (if applicable) and applied restrictions or
            safeguards that fully take into consideration the nature of the data
            and the risks involved, such as for instance strict purpose
            limitation, access restrictions (including access only for staff
            having followed specialised training), keeping a record of access to
            the data, restrictions for onward transfers or additional security
            measures.
          </p>
          <p class="processing">
            - The categories of personal data may include sensitive data
            depending on the Content uploaded by the User. Since Wikimedia has
            no knowledge of the specific categories processed it lies in the
            User’s responsibility to ensure that the safeguards are sufficient
            to process the specific categories of personal data.
          </p>
          <p class="italic">Nature of the processing</p>
          <p class="processing">
            - Any processing necessary to fulfil the contractual purposes.
            Especially collection, storage, disclosure and dissemination and
            making publicly available as well as anonymization and/or deletion.
          </p>
          <p class="italic">
            Purpose(s) for which the personal data is processed on behalf of the
            controller
          </p>
          <p class="processing">
            - Publishing the Wikibase as well as the data uploaded to and
            contained in it and making it available to the public. Wikimedia
            does not act as a processor for the User with regard to the
            processing of personal data of persons browsing the Wikibase and any
            personal data that is processed to establish the connection.
          </p>
          <p class="italic">Duration of the processing</p>
          <p class="processing">
            - The rules of the Agreement regarding the Term and Termination
            apply.
          </p>
          <p class="italic">
            For processing by (sub-) processors, also specify subject matter,
            nature and duration of the processing
          </p>
          <p></p>
          <p></p>
          <h5>ANNEX III</h5>
          <p>
            <b>
            Technical and organisational measures including technical and
            organisational measures to ensure the security of the data
            </b>
          </p>
          <p>
            You may obtain a copy of our technical and organisational measures
            on request, to do so please send an email to:
            datenschutz@wikimedia.de.
          </p>
          <h5>ANNEX IV</h5>
          <h5>List of sub-processors</h5>
          <h5>EXPLANATORY NOTE:</h5>
          <p>
            This Annex needs to be completed in case of specific authorisation
            of sub-processors (Clause 7.7(a), Option 1).
          </p>
          <p>
            The controller has authorised the use of the following
            sub-processors:
          </p>
          <ol start="1">
            <li>
              <p>Name: Google Ireland Ltd.</p>
              <p>Address: Gordon House, Barrow Street, Dublin 4</p>
              <p>Contact person’s name, position and contact details:</p>
              <p>Google LLC</p>
              <p>1600 Amphitheatre Parkway</p>
              <p>Mountain View, CA 94043</p>
              <p>USA</p>
              <p>Tel: +1 650 253 0000</p>
              <p>E-Mail: support-de@google.com</p>
              <p>
                Description of the processing (including a clear delimitation of
                responsibilities in case several sub-processors are authorised):
                Hosting of the Wikibase.cloud service
              </p>
            </li>
            <li>
              <p>Name: Mailgun Technologies, Inc</p>
              <p>Address: 112 E Pecan St #1135, San Antonio, TX 78205</p>
              <p>Description of the processing : Email service provisioning</p>
            </li>
          </ol>

        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: 'TermsOfUse',
  computed: {}
}
</script>

<style>
h5 {
  margin-bottom: 16px;
}
.italic {
  font-style: italic;
}
.first-column {
  text-align: center;
  min-width: 60px;
}
.processing {
  padding: 20px;
}
</style>
